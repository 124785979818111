/** @format */

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Base from "./components/Layouts/Base";
import { keepTheme } from "./components/Utils/Theme";
import { useEffect } from "react";
import ReactGA from 'react-ga';
ReactGA.initialize('G-9DSNE1RWZ8');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  useEffect(() => {
    keepTheme();
  });
  return (
    <div className='App'>
      <BrowserRouter>
        <Base>
          <Routes>
            <Route index path='/' element={<Home />} />
            {/* <Route path="/*" element={<PageNotFound />}/> */}
          </Routes>
        </Base>
      </BrowserRouter>
    </div>
  );
}

export default App;
