/** @format */

import React from "react";
import { IoIosArrowForward } from "react-icons/io";

const Guarantee = () => {
  return (
    <>
      <div className='guarantee_sec'>
        <div className='container text-center'>
        <a
              href='https://www.sgiacademy.com/whitelisthoc'
              target='_blank'
              rel='noopener noreferrer'>
              {" "}
              <button className='btn-style m-auto text-center d-flex justify-content-center my-3 '>
                {" "}
                <strong>Whitelist your BNB Address now for €1,-</strong>
                </button>
            </a>
          <h3>
            Keep yourself in the loop with the latest developments in real
            estate and blockchain technology.
          </h3>
          <p>Reach Out to Us</p>
          <p>
            Need tailored assistance? Our team is just a message away, ready to
            address your inquiries.
          </p>
        
          <div className='image'>
            {/* <img src='assets/images/mobile.png' className='img-fluid' alt='' /> */}
          </div>
          <a
              href='https://t.me/Home_OwnersClub'
              target='_blank'
              className="  "
              rel='noopener noreferrer'>
          <div className='hero_btn  '>
            <button className="position-relative">
              <div className='d-flex justify-content-between align-items-center '>
                <p className='top_text'>Contact Us</p>
                <IoIosArrowForward className='icon' />
              </div>
            </button>

          </div>
             
            </a>
        </div>
      </div>
    </>
  );
};

export default Guarantee;
