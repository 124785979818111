/** @format */

import React, { useEffect, useState } from "react";
import DarkModeToggle from "react-dark-mode-toggle";
import { setTheme } from "./Theme";


const Toggle = () => {
  const [togClass, setTogClass] = useState("dark");
  let theme = localStorage.getItem("theme");

  const handleOnClick = () => {
    if (localStorage.getItem("theme") === "theme-dark") {
      setTheme("theme-light");
      setTogClass("light");
    } else { 
      setTheme("theme-dark");
      setTogClass("dark");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("theme") === "theme-dark") {
      setTogClass("dark");
    } else if (localStorage.getItem("theme") === "theme-light") {
      setTogClass("light");
    }
  }, [theme]);

  return (
    <div className='container--toggle'>
      {togClass === "light" ? (
        <DarkModeToggle onChange={handleOnClick}  size={80} />
      ) : (
        <DarkModeToggle onChange={handleOnClick} checked size={80} />
      )}
      <label htmlFor='toggle' className='toggle--label'>
        <span className='toggle--label-background'></span>
      </label>
    </div>
  );
};
export default Toggle;
