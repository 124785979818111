/** @format */

import React from "react";

const Referral = () => {


  return (
    <div className='container-fluid Referral'>
      <div className='container'>
        <h5>
          Welcome to the HOC{" "}
          <span className='text-warning'>Referral & Affiliate Program</span>{" "}
        </h5>
        <h2>HOC Referral and Affiliate Program</h2>
        <p className='w-75 w-sm-100 m-auto  text-center'>
          Unlock a world of opportunities and rewards with the Home Owners Club
          (HOC) Referral & Affiliate Program. We believe that great
          opportunities are even better when shared, and we want to show our
          appreciation for your support by inviting you to join our referral and
          affiliate family.
        </p>
        <div className='row mt-5'>
          <div className='col-md-6 border-end'>
            <div className='top_text'>
              <h3>Why Become an HOC Affiliate?</h3>
              <ol>
                <li>
                  <strong className='text-warning'>Earn Commissions</strong> By
                  joining our affiliate program, User can earn 5% commissions on
                  real estate investments made by those you refer to HOC. It's a
                  simple way to generate passive income while sharing the
                  exciting world of real estate investment.
                </li>
                <li>
                  <strong className='text-warning'>
                    Share a Valuable Opportunity
                  </strong>{" "}
                  You're not just promoting any product; you're introducing
                  people to a groundbreaking platform that makes real estate
                  accessible and hassle-free. Your referrals get the chance to
                  invest in property without the usual complexities.
                </li>
                <li>
                  <strong className='text-warning'>
                    Contribute to HOC's Growth
                  </strong>{" "}
                  As an affiliate, Users play a vital role in helping HOC grow
                  and achieve its mission of democratizing real estate
                  investment. User support not only benefits user but also
                  strengthens our community.
                </li>
              </ol>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='top_text mx-5'>
              <h3>How the HOC Affiliate Program Works</h3>
              <li>
                <strong className='text-warning'>
                  Register as an Affiliate
                </strong>{" "}
                If you're already an HOC member, becoming an affiliate is a
                breeze. New to HOC? No worries – you can create an account and
                become part of our vibrant community.
              </li>
              <li>
                <strong className='text-warning'>
                  Get Your Unique Referral Link
                </strong>{" "}
                Once you're an affiliate, you'll receive a unique referral link.
                This link is your key to earning commissions.
              </li>
              <li>
                <strong className='text-warning'>Share Your Link</strong> Start
                sharing your referral link with friends, family, colleagues, and
                anyone you think would benefit from HOC's real estate investment
                opportunities. users can share it through social media, email,
                or any other channel they prefer.
              </li>
            </div>
          </div>
          <div className='hero_btn '>
            <div>
              <p className='top_text text-center fw-bold text-warning'>
                Maximizing Your Earnings
              </p>
              <p>To make the most of your affiliate status:</p>

              <span>
                <strong className="text-warning fw-bold">Educate and Engage: </strong>
                Encourage your referrals to learn about HOC, its benefits, and
                how it works. The more informed they are, the more likely they
                are to invest.
              </span><br />
              <span>
                <strong className="text-warning fw-bold">Build a Network: </strong> Consider creating a network of
                like-minded individuals who can refer others. This way, User can
                earn 5% commissions not only from their direct referrals but
                also from those referred by your network.
              </span><br />
              <span>
                <strong className="text-warning fw-bold">Stay Informed: </strong> Keep an eye on our updates and
                announcements. We may introduce special promotions or bonuses
                for affiliates to boost your earnings
              </span><br />
              <span>
                Join the HOC Referral & Affiliate Program and become a key
                player in shaping the future of real estate. It's not just about
                earning commissions; it's about making a difference and being
                part of a community that's transforming how we invest in real
                estate.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referral;
