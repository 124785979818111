/** @format */

import React, { useState } from "react";

const Airdrop = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleReadMore1 = () => {
    setIsExpanded1(!isExpanded1);
  };
  return (
    <>
      <div className='section'>
        <div className='container'>
          <h5>
            Introducing the HOC{" "}
            <span className='text-warning'>Airdrop & Bounty Program</span>{" "}
          </h5>
          <h2>Your Chance to Win $10,000!</h2>
          <p className='w-75 w-sm-100 m-auto text-light text-center'>
            At Home Owners Club (HOC), we're dedicated to revolutionizing the
            world of real estate, making it accessible to everyone. As part of
            our ongoing commitment to our community, we're thrilled to announce
            our Airdrop & Bounty Program, offering our members the opportunity
            to earn a generous $10,000 in rewards.
          </p>
          <div className='container mt-5'>
            <div className='top_text'>
              <h3>What is the HOC Airdrop & Bounty Program?</h3>
              <p className='w-75 '>
                Our Airdrop & Bounty Program is designed to reward our valued
                community members for their support and engagement. By
                participating in this program, users not only contribute to the
                growth of HOC but also have a chance to earn substantial
                rewards.
              </p>
            </div>
            <div className='row'>
              <div className='col-md-5'></div>
              <div className='col-md-7'>
                <div className='right_text'>
                  <h3>How to Participate:</h3>
                  <p>
                    1. Airdrop Rewards: Simply there will be various Promotional
                    tasks and those user who registered for airdrop and
                    completed the given tasks will be automatically qualify for
                    our Airdrop rewards.
                  </p>
                  {isExpanded ? (
                    <>
                      <p>
                        2. Bounty Tasks: Get actively involved in our community
                        by completing various bounty tasks. These tasks may
                        include sharing HOC content on social media, creating
                        informative blog posts, making videos, and more. User
                        will earn rewards based on the quality and impact of
                        their contributions.
                      </p>
                      <p>
                        3. Join a Thriving Community: Connect with like-minded
                        individuals who share your passion for real estate and
                        the future of blockchain technology.
                      </p>
                    </>
                  ) : null}

                  <div className=' mt-3' onClick={toggleReadMore}>
                    <button className='btn-style '>
                      {" "}
                      {isExpanded ? "Read Less" : "Read More"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-7'>
                <div className='right_text'>
                  <h3>How to Get Started:</h3>
                  <p>
                    1. Keep an eye on our official communication channels for
                    updates and announcements related to the Airdrop & Bounty
                    Program.
                  </p>
                  {isExpanded1 ? (
                    <>
                      <p>
                        2. When bounty tasks become available, follow the
                        instructions provided and submit your contributions.
                      </p>
                      <p>
                        3. Watch your rewards grow as you actively engage with
                        HOC and its community.
                      </p>
                    </>
                  ) : null}

                  <div className=' mt-3' onClick={toggleReadMore1}>
                    <button className='btn-style '>
                      {" "}
                      {isExpanded1 ? "Read Less" : "Read More"}
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-md-5 text-end'>
                <p className='text-warning fw-bold'>
                  Stay tuned for more details, guidelines, and specific tasks as
                  we roll out the Airdrop & Bounty Program. It's your
                  opportunity to be a part of HOC's exciting journey and be
                  rewarded for your dedication.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Airdrop;
