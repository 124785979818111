/** @format */

import React from "react";
import Hero from "../components/Elements/Hero";
import Marketing from "../components/Elements/Marketing";
import Challenge from "../components/Elements/Challenge";
import Work from "../components/Elements/Work";
import Cards from "../components/Elements/Cards";
import Airdrop from "../components/Elements/Airdrop";
import Grid from "../components/Elements/Grid";
import Guarantee from "../components/Elements/Guarantee";
import Referral from "../components/Elements/Referral";

const Home = () => {
  return (
    <>
      <Hero />
      <Marketing />
      <Challenge />
      <Work />
      <Cards />
      <Airdrop />
      <Grid />
      {/* <Gamechanger /> */}
      <Referral />
      <Guarantee />
    </>
  );
};

export default Home;
