/** @format */

import React from "react";
import { BsArrowUpRight } from "react-icons/bs";
const Grid = () => {
  return (
    <>
      <div className='grid_sec'>
        <div className='container'>
          <a
            href='https://www.sgiacademy.com/whitelisthoc'
            target='_blank'
            rel='noopener noreferrer'>
            {" "}
            <button className='btn-style m-auto text-center d-flex justify-content-center my-3 '>
              {" "}
              <strong>Whitelist your BNB Address now for €1,-</strong>
            </button>
          </a>
          <h3>HOC Guideline Tutorials...</h3>
          <div className='row mt-5'>
            <div className='col-md-6 mt-3'>
              <div className='video_main'>
                {/* <iframe
                  src=""
                  frameBorder={0}
                  allowFullScreen
                  wmode="opaque"
                  id="fitvid430182"
                  data-ready="true"
                  contentEditable="false"
                /> */}
                <iframe
                  allow='autoplay'
                  src='https://www.youtube.com/embed/9TaO-LCrtBQ?si=KnhhpIU1MFEJHGE5'
                  frameBorder={0}
                  allowFullScreen
                  wmode='opaque'
                  id='fitvid458108'
                  title='About HOC'
                  data-ready='true'
                  className='cf-sticky-video-active rounded-4'
                  contentEditable='false'
                />
              </div>

              <p>Free Tools</p>
              <h4>– HOC News</h4>
            </div>
            <div className='col-md-6 mt-3'>
              <a
                href='https://medium.com/@homeownersclub/transforming-real-estate-a-glimpse-into-the-home-owners-club-hoc-e316b4a4fef2'
                target='_blank'
                rel='noopener noreferrer'>
                <img
                  src='https://miro.medium.com/v2/resize:fit:828/format:webp/1*b__Ksh8DPT8chYzTCRsQsA.png'
                  className='img-fluid'
                  alt=''
                />
              </a>
            </div>
            <div className='col-md-6 mt-3'>
              <div className='video_main'>
                {/* <iframe
                  src=""
                  frameBorder={0}
                  allowFullScreen
                  wmode="opaque"
                  id="fitvid430182"
                  data-ready="true"
                  contentEditable="false"
                /> */}

                <a
                  href='https://medium.com/@homeownersclub/discover-the-future-of-real-estate-welcome-to-home-owners-club-hoc-e0c412ac021b'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img
                    src='https://miro.medium.com/v2/resize:fit:828/format:webp/1*WFoFmMMZVXuuvTmmCdfeHg.png'
                    className='img-fluid'
                    alt=''
                  />
                </a>
              </div>
              <p>Free Tools</p>
              <h4>– HOC News</h4>
            </div>
            <div className='col-md-6 mt-3'>
              <div className='video_main'>
                {/* <iframe
                  src=""
                  frameBorder={0}
                  allowFullScreen
                  wmode="opaque"
                  id="fitvid430182"
                  data-ready="true"
                  contentEditable="false"
                /> */}
                <img
                  src='assets/images/vid.png'
                  className='img-fluid w-100'
                  alt=''
                />
              </div>
              <p>Free Tools</p>
              <h4>– HOC News</h4>
            </div>
          </div>
          <a
            href='https://www.sgiacademy.com/whitelisthoc'
            target='_blank'
            rel='noopener noreferrer'>
            {" "}
            <button className='btn-style m-auto text-center d-flex justify-content-center my-3 '>
              {" "}
              <strong>Whitelist your BNB Address now for €1,-</strong>
            </button>
          </a>

          <div className='mt-5 text-center '>
            <h3>Stay Informed with Our Blog & Updates</h3>
            <img
              src='assets/images/lightfsd.png'
              className='img-fluid'
              alt=''
            />
          </div>
          <div className='text-center mt-5'>
            <a
              href='https://www.screaminggoatsinnovations.com/blog'
              target='_blank'
              rel='noopener noreferrer'
              className='btn-style  text-dark'>
              Our Blogs &nbsp;
              <BsArrowUpRight />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Grid;
